import React, { useMemo } from 'react'
import { Input } from 'antd'
import FieldWrapper from '../FieldWrapper'
import { capitalizeFirstLetter } from 'Utils'
import { connect } from 'react-redux'

const StringField = (props) => {
  const { name, onChange, values, placeholder, disabled, initialValues, inputType } = props

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <Input
          style={{ display: inputType }}
          name={name}
          type={inputType || 'text'}
          disabled={disabled}
          placeholder={placeholder || capitalizeFirstLetter(name)}
          value={values[name]}
          onChange={(e) => {
            // todo: for preview data, remove later
            onChange(name, e.target.value)
            props.setFieldValue(name, e.target.value)
          }}
        />
      )}
    </FieldWrapper>
  )
}

export default connect(null, (dispatch) => ({
  setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
}))(StringField)
