import React, { useMemo } from 'react'
import { Select } from 'antd'
import CommonTitle from '../CommonTitle'
import { Field, Form } from 'formik'
import FieldWrapper from '../FieldWrapper'
import { connect } from 'react-redux'

const { Option } = Select

const SelectField = (props) => {
  const { multi, options, disabled, name, values, onChange, placeholder } = props

  function handleChangeSelect(value) {
    onChange(name, value)
    props.setFieldValue(name, value)
  }

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <Select
          mode={multi ? 'multiple' : ''}
          disabled={disabled}
          allowClear
          style={{ width: '100%' }}
          placeholder={placeholder || 'Select an option'}
          name={name}
          value={values[name]}
          onChange={handleChangeSelect}
        >
          {options.map(({ name, code }) => (
            <Option key={code}>{name}</Option>
          ))}
        </Select>
      )}
    </FieldWrapper>
  )
}

SelectField.defaultProps = {
  multi: false,
  disabled: false,
  options: [
    { name: 'Option 1', code: 'Op1' },
    { name: 'Option 2', code: 'Op2' },
    { name: 'Option 3', code: 'Op3' },
  ],
  codes: null,
  handleChange: () => {},
}

export default connect(null, (dispatch) => ({
  setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
}))(SelectField)
