import React, { useEffect, useMemo, useState } from 'react'
import { SketchPicker } from 'react-color'
import FieldWrapper from '../FieldWrapper'
import { connect } from 'react-redux'
import { Button, Popover } from 'antd'
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker'

const ColorPickerComponent = (props) => {
  const { name, onChange, values } = props
  const [color, setColor] = useState(
    'linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)'
  )
  const { setSolid, setGradient, valueToHex, getGradientObject } = useColorPicker(color, setColor)
  useEffect(() => {
    setColor(values[name])
  }, [values[name]])
  function convertRgbToRgba(input) {
    // Regular expression to match the rgb format
    const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/

    // Test if the input matches the rgb format
    const match = input?.match(rgbRegex)

    if (match) {
      // Extract the RGB values
      const r = match[1]
      const g = match[2]
      const b = match[3]

      // Convert to rgba with opacity = 1
      return `rgba(${r}, ${g}, ${b}, 1)`
    } else {
      // If input is not in rgb format, return the original input or handle as needed
      return input?.toLowerCase()
    }
  }

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <Popover
          content={
            // <SketchPicker
            //   color={tempColor}
            //   onChangeComplete={(color) => {
            //     onChange(name, color?.hex)
            //     setTempColor(color?.hex)
            //     props.setFieldValue(name, color?.hex)
            //   }}
            // />
            <div>
              {/* <button onClick={setSolid}>Solid</button> */}
              {/* <button onClick={setGradient}>Gradient</button> */}
              <ColorPicker
                value={color}
                onChange={(color) => {
                  console.log('color', convertRgbToRgba(color))
                  onChange(name, convertRgbToRgba(color))
                  setColor(convertRgbToRgba(color))
                  props.setFieldValue(name, convertRgbToRgba(color))
                }}
                hideControls={props?.disableGradient ? props?.disableGradient : false}
                hideAdvancedSliders
                hideColorGuide
                hideEyeDrop
                hideColorTypeBtns={props?.disableRgba ? props?.disableRgba : false}
                // hideControls
              />
            </div>
          }
          title="Choose your color"
          trigger="click"
        >
          <div
            style={{
              background: color || '#FFDAAE',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              cursor: 'pointer',
            }}
          ></div>
        </Popover>
      )}
    </FieldWrapper>
  )
}

export default connect(null, (dispatch) => ({
  setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
}))(ColorPickerComponent)
